.highlight-blue {
    background-color: #B9E7FE;
  }
  
  .highlight-orange {
    background-color: #FEE685;
  }
  
  .highlight-red {
    background-color: #FFA0AE; 
  }