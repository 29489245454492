.Layout {
  height: 100vh;

  .Header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;

    .HomepageLink {
      height: 3rem;
      width: 8rem;

      .LeoLogo {
        width: 100%;
        height: 100%;
      }
    }

    .Menu {
      flex: 1;
    }
  }

  .Content {
    overflow-y: auto;
    padding: 1rem;
  }
}
