.formWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;

  .form {
    width: 90%;
    max-width: 25rem;
    margin: 0 auto;
    padding: 3rem 2rem 2rem;
    border-radius: 0.75rem;
    background: white;
    border: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    text-align: center;
  }

  .LeoLogo {
    height: 3rem;
    width: 8rem;
    margin-bottom: 2rem;
  }
}
